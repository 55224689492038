<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="4" md="2">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/admin/admin-products-prices'"
                style="text-decoration: none"
              >
                <v-btn color="secondary">
                  <v-icon color="black"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row></v-col
          >
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="14" sm="12" md="12">
            <material-card
              icon="mdi-clipboard-text-clock"
              icon-small
              color="secondary"
              :title="product.productName"
              :subtitle="product.subDepartment.subDepartmentName"
            >
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>

              <v-data-table
                :headers="headers"
                :items="productPrices"
                :items-per-page="5"
                :search="search"
              >
                <template v-slot:[`item.toDate`]="{ item }">
                  <span>{{ item.toDate | localDate }}</span>
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  <span>${{ formatPrice(item.price) }}</span>
                </template>
              </v-data-table>
            </material-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </div>
</template>

<style>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-weight: bold;
}

.row1 {
  margin-top: -25px;
}

.itemsLayout {
  height: 415px;
  overflow: hidden;
  overflow-y: auto;
}

.addbutton {
  float: right;
}

.temp {
  background-color: rgb(255, 255, 255);
}
</style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MaterialCard from "../components/MaterialCard.vue";
var moment = require("moment");

export default {
  components: { MaterialCard, Loading },
  data() {
    return {
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,

      search: "",

      headers: [
        // { text: "ID", value: "productId" },
        // { text: "Product Name", value: "productName" },

        { text: "Updated Date", value: "toDate" },
        { text: "Updated Price", value: "price" },
      ],

      editedIndex: "",
      editedItem: {
        lastPriceUpdatedTime: "",
        lastPrice: "",
        retailPrice: "",
      },

      defaultItem: {
        lastPriceUpdatedTime: "",
        lastPrice: "",
        retailPrice: "",
      },

      productPrice: {
        toDate: "",
        price: "",
        productId: "",
      },

      defaultProductPrice: {
        toDate: "",
        price: "",
        productId: "",
      },

      page: 1,
      pageCount: null,
      start: 0,
      end: 11,
      dataFiltered: [],

      productPrices: [],
      product: {
        productName: "",
        subDepartment: { subDepartmentName: "" },
      },
    };
  },
  filters: {
    localDate: function (date) {
      var localDate = moment.utc(date).local().format("MMM Do YYYY - hh:mm A");
      return date != null ? localDate : " Not Yet";
    },
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Customer" : "Edit Customer";
    },
  },

  watch: {
    page: function () {
      this.changePage();
    },
  },

  created() {
    this.getDataFromApi();
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    updatePrice(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.productPrices.indexOf(item);
      this.dialog = true;
    },

    getDataFromApi() {
      var currentUrl = window.location.pathname;
      let vars = currentUrl.split("/");
      vars.reverse();
      let itemId = vars[0];
      itemId = parseInt(itemId);

      this.$http.get("/ProductMaster/" + itemId).then((response) => {
        this.product = response.data.data;
      });

      this.isLoading = true;

      this.$http
        // .get("/ProductPrice/")
        .get("/ProductPrice/Product/" + itemId)
        .then((response) => {
          //  this.productPrices = response.data.data;
          //  this.productPrices = this.productPrices.reverse();
          console.log(response.data);
          //alert("Error in : /ProductPrice/Product/id");
          this.changePage();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight",
            });
          }
        });
    },
    updateData() {
      this.page = 1;
      this.changePage();
    },
    changePage() {
      var count = this.productPrices.length;
      var float = count / this.end;
      var int = parseInt(count / this.end);
      if (float > int) {
        this.pageCount = int + 1;
      } else {
        this.pageCount = int;
      }

      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.productPrices.slice(newStart, newEnd);

      this.dataFiltered = items;
      this.isLoading = false;
    },

    setDefault() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.productPrice = Object.assign({}, this.defaultProductPrice);
      });
    },
    close() {
      this.setDefault();
      this.dialog = false;
    },
  },
};
</script>
